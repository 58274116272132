<template>
  <div>
    <div
      class="modalScroll d-flex flex-column align-items-center"
      style="width: auto; height: auto"
    >

      <div
        class="p-1"
        style=""
      >
        <!-- {{ empData }} -->
        <button
          class="btn btn-close mb-1 saj-subtitle"
          aria-label="Close modal"
          style="
          color: white;
          background: #ff0000;
        "
          @click="downloadPerformancePDF(empData), clear(), getScore(empData.jd_kra_status, empData.performance_id)"
        >
          {{ $t("Download PDF") }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import {

} from "bootstrap-vue"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  props: {
    empData: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      businessUnitList: [],
      allbusinessUnit: [],
      subsidiary_name: null,
      departmentName: null,
      myPerformance: null,
      training_development: [],
      other_contribution: [],
      active_competency_core_performance: [],
      active_competency_behaviour_performance: [],
      kra: [],
      kraList: [],
      allUserData: null,

      // Data for summary score
      kra_id: null,
      contribution: null,
      contributionPercent: 0,
      contributionPercentTeam: 0,
      scoreYearSelected: "a",
      scoreYearoptions: [
        { value: "a", text: "2022" },
        { value: "b", text: "2021" },
        { value: "c", text: "2020" },
      ],
      overall_total2: "",
      overall_total_manager: "",
      table: {
        // user score
        p1_kra: 0,
        p1_job_description: 0,
        p2_competency_core: 0,
        p2_competency_behaviour: 0,
        p3_training_development: 0,
        p3_contribution: 0,
        accumulative1: 0,
        accumulative2: 0,
        accumulative3: 0,
        accumulative4: 0,
        overall_total: 0,
        // manager score
        p1_kra_manager: 0,
        p1_job_description_manager: 0,
        p2_competency_core_manager: 0,
        p2_competency_behaviour_manager: 0,
        p3_training_development_manager: 0,
        p3_contribution_manager: 0,
        accumulative1_manager: 0,
        accumulative2_manager: 0,
        accumulative3_manager: 0,
        accumulative4_manager: 0,
        overall_total_manager: 0,
        bordered: true,

        scoreList: [
          {
            keputusan: 'Key Result Area', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Core Competencies', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Behavioural Competencies', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Training and Development', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Internal / Industrial Involvement', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Summary Score (%)', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
        ],
        fields: [
          {
            key: 'keputusansss', label: `${this.$t('Result')}`, thClass: 'text-center',
          },
          {
            key: 'pemberattt', label: `${this.$t('Weightage')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah', label: `${this.$t('Score')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul', label: `${this.$t('Cumulative Score')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_manager', label: `${this.$t('Manager Score')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul_manager', label: `${this.$t('Manager Cumulative Score')}`, thClass: 'text-center', tdClass: 'text-center',
          },

        ],
      },
      scaleList: [],
      scale1_eng: [],
      scale2_eng: [],
      scale3_eng: [],
      scale4_eng: [],
      scale5_eng: [],
      scale6_eng: [],
      internal_industry_status: null,
      suggestions: null,
      hod_data: null,
      appraiser_hod_name: null,
      appraiser_hod_position: null,
      appraiser_hr_name: null,
      appraiser_hr_position: null,
      appraisee_comment_data: null,
      appraisee_comment: null,
      appraisee_date: null,
      appraisee_data: null,
      appraisee_fullname: null,
      appraisee_position: null,
      appraiser_comment_data: null,
      appraiser_comment: null,
      appraiser_data: null,
      appraiser_fullname: null,
      appraiser_position: null,
      appraiser_date: null,
      appraiser_hod_hr_comment_data: null,
      appraiser_hod_hr_comment: null,
      appraiser_hod_hr_date: null,
      pemberat: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    this.getSubsidiaryById()
    this.getDepartmentById()
    this.scoreRating()
    this.getWeightage()
  },
  methods: {
    getSubsidiaryById(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getById?id=${this.empData.subsidiary_id}`).then(response => {
        this.allbusinessUnit = response.data.data
        this.allbusinessUnit.forEach(b => {
          this.businessUnitList.push({
            text: b.subsidiary_name,
            value: b.id,

          })
        })
        this.subsidiary_name = this.allbusinessUnit.map(x => (x.subsidiary_name))
        // console.log(this.subsidiary_name)
      })
    },
    getDepartmentById(){
      this.$axios.get(`${this.$baseUrl}/departments/get_by_department_id?department_id=${this.empData.department_id}`).then(response => {
        this.departmentName = response.data.data.name
      })
    },
    clear(){
      this.kraList = []
    },
    scoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.scaleList = response.data.data
        this.scale1_eng = this.scaleList[5].definition_en
        this.scale2_eng = this.scaleList[4].definition_en
        this.scale3_eng = this.scaleList[3].definition_en
        this.scale4_eng = this.scaleList[2].definition_en
        this.scale5_eng = this.scaleList[1].definition_en
        this.scale6_eng = this.scaleList[0].definition_en
      })
    },
    downloadPerformancePDF(empData){
      // console.log('userId & perf id', empData.user_id, empData.performance_id)
      this.$axios.get(`${this.$baseUrl}/download_pdf_data?user_id=${empData.user_id}&performance_id=${empData.performance_id}`).then(response => {
        // console.log(response)
        this.myPerformance = response.data.data
        this.triggerGenerateReport()
        this.kra = this.myPerformance.kra
        this.active_competency_core_performance = this.myPerformance.active_competency_core_performance
        this.active_competency_behaviour_performance = this.myPerformance.active_competency_behaviour_performance
        this.training_development = this.myPerformance.training_development
        this.other_contribution = this.myPerformance.other_contribution
        this.internal_industry_status = this.myPerformance.internal_industry_status
        // appraisee comment
        this.appraisee_comment_data = this.myPerformance.comment_performance
        if (this.appraisee_comment_data !== null) {
          this.appraisee_comment = this.appraisee_comment_data.comment
          this.appraisee_date = this.appraisee_comment_data.updated_at
        } else {
          this.appraisee_comment = '-'
          this.appraisee_date = '-'
        }

        this.appraisee_data = this.myPerformance.appraisee
        if (this.appraisee_data !== null) {
          this.appraisee_fullname = this.appraisee_data.full_name
          this.appraisee_position = this.appraisee_data.position
        } else {
          this.appraisee_fullname = '-'
          this.appraisee_position = '-'
        }

        // appraiser comment
        this.appraiser_comment_data = this.myPerformance.appraiser_comment
        if (this.appraiser_comment_data !== null) {
          this.appraiser_comment = this.appraiser_comment_data.comment
          this.appraiser_date = this.appraiser_comment_data.updated_at
        } else {
          this.appraiser_comment = '-'
          this.appraiser_date = '-'
        }

        this.appraiser_data = this.myPerformance.supervisor
        if (this.appraiser_data !== null) {
          this.appraiser_fullname = this.appraiser_data.full_name
          this.appraiser_position = this.appraiser_data.position
        } else {
          this.appraiser_fullname = '-'
          this.appraiser_position = '-'
        }

        // appraiser hod & hr comment
        this.appraiser_hod_hr_comment_data = this.myPerformance.appraiser_sv_hod_comment
        if (this.appraiser_hod_hr_comment_data) {
          this.appraiser_hod_hr_comment = this.appraiser_hod_hr_comment_data.comment
          this.appraiser_hod_hr_date = this.appraiser_hod_hr_comment_data.updated_at
        } else {
          this.appraiser_hod_hr_comment = '-'
          this.appraiser_hod_hr_date = '-'
        }

        // part 4 comment
        this.hod_data = response.data.data.appraiser_sv_hod
        if (this.hod_data !== null) {
          this.appraiser_hod_name = this.hod_data.full_name
          this.appraiser_hod_position = this.hod_data.position
        }
        this.appraiser_hr_name = response.data.data.appraiser_hod_hr.full_name
        this.appraiser_hr_position = response.data.data.appraiser_hod_hr.position
        // part 4 comment
        // this.hod_data = response.data.data.appraiser_sv_hod
        // if (this.hod_data !== null) {
        //   this.appraiser_hod_name = this.hod_data.full_name
        //   this.appraiser_hod_position = this.hod_data.position
        // }
        // this.appraiser_hr_name = response.data.data.appraiser_hod_hr.full_name
        // this.appraiser_hr_position = response.data.data.appraiser_hod_hr.position
        // suggestion
        if (this.myPerformance.suggestions.length === 0) {
          this.suggestions = '-'
        } else {
          this.suggestions = this.myPerformance.suggestions[0].suggestion
        }
        this.$emit('close')
        // this.triggerGenerateReport()
      })
    },
    triggerGenerateReport() {
      this.showPerformance()
    },
    getImage(url){
      return new Promise(resolve => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous') // getting images from external domain

        // eslint-disable-next-line func-names
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight

          // next three lines for white background in case png has a transparent background
          const ctx = canvas.getContext('2d')
          ctx.fillStyle = '#fff' /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height)

          canvas.getContext('2d').drawImage(this, 0, 0)

          resolve(canvas.toDataURL('image/jpeg'))
        }

        image.src = url
      })
    },
    async getKra() {
      const kraList = [];
      if (this.kra !== null && this.kra[0].key_result_area.length != 0) {
        this.kra.forEach((x) => {
          x.key_result_area.forEach((z) => {
            kraList.push({
              objective_name: x.objective_name ?? "-",
              kra_name: z.kra_name ?? "-",
              kpi: z.mid_year_kpi ?? "-",
              measurement: z.measurement ?? "-",
              tracking: z.tracking ?? "-",
              deadline: z.deadline ?? "-",
              self_score: z.rating.toString() ?? "-",
              manager_score: z.manager_rating.toString() ?? "-",
            });
          });
        });
      } else {
        const response = await this.$axios.get(
          `${this.$baseUrl}/kra/get_review?cycle_id=${this.empData.performance_id}`
        );
        const obj = response.data.data.objective;
        obj.forEach((x) => {
          x.key_result_area.forEach((z) => {
            kraList.push({
              objective_name: x.objective_name ?? "-",
              kra_name: z.kra_name ?? "-",
              measurement: z.achievement.measurement ?? "-",
              tracking: z.achievement.tracking ?? "-",
              kpi: z.achievement.achievement ?? "-",
              deadline: z.achievement.due_date ?? "-",
              self_score: z.achievement.rating.toString() ?? "-",
              manager_score: z.manager_rating ?? "-",
            });
          });
        });
      }
      return kraList;
    },
    getCoreData(){
      if (this.active_competency_core_performance !== null){
        const myCore = this.active_competency_core_performance.map(x => ({
          subcore: x.subcore.name_bi.toString(),
          description: x.subcore.description_bi.toString(),
          self_score: x.self_score.toString(),
          manager_score: x.manager_score.toString(),

        }))
        // console.log('core', myCore)
        return myCore
      }
      return null
    },
    getBehavData(){
      if (this.active_competency_behaviour_performance !== null){
        const myBehav = this.active_competency_behaviour_performance.map(x => ({
          behavioural_competency: x.behaviour.name_bi.toString(),
          self_score: x.self_score.toString(),
          manager_score: x.manager_score.toString(),

        }))
        return myBehav
      }
      return null
    },
    getTrainingData() {
      if (this.training_development !== null){
        const myPerf = this.training_development.map(x => ({
          program_title: x.program_name.toString(),
          start_date: x.start_date.toString(),
          end_date: x.end_date.toString(),
          hours: x.hour.toString(),
          self_score: x.self_score.toString(),
          manager_score: x.manager_score.toString(),
        }))

        return myPerf
      }
      return null
    },
    getOtherContrData() {
      if (this.other_contribution !== null){
        const myOther = this.other_contribution.map(x => ({
          involvement_activities: x.involvement.toString(),
          position: x.position_english.toString(),
          date: x.date.toString(),
          self_score: x.self_score.toString(),
        }))

        return myOther
      }
      return null
    },
    async showPerformance(){
      // eslint-disable-next-line new-cap
      const doc = new jsPDF("landscape", "mm", "a4")
      // const width = doc.internal.pageSize.getWidth()
      const logo = await this.getImage("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Ranhill_Utilities_Berhad_Logo_%28Original%29.png/922px-Ranhill_Utilities_Berhad_Logo_%28Original%29.png?20211125033701")
      doc.addImage(logo, 'png', 20, 10, 18.8125, 27.5, 'a', 'FAST')

      // Front Page
      const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

      doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text(`${this.subsidiary_name}`, 150, 12, null, null, "center")
      doc.setFontSize(12)
      doc.text("PERFORMANCE APPRAISAL FORM", 150, 19, null, null, "center")
      doc.setFontSize(11)
      doc.text("Executive : Jr. Exe / Exe / Sr. Exe", 150, 25, null, null, "center")
      doc.text("DEPARTMENT                :______________________________________", 200, 33, null, null, "right")
      doc.text("REVIEW PERIOD FOR  :____________________(mm/yy) to ____________________(mm/yy)", 224, 40, null, null, "right")

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 50, pageWidth - 20, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("EMPLOYEE INFORMATION", 150, 55.5, "center")
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 58, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Employee No.", 60, 64, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 58, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 66, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Employee Name", 60, 72, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 66, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 74, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Position", 60, 79.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 74, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 82, 100, 8, 'FD')
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 82, pageWidth - 120, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Job Grade", 60, 87, "center")
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 90, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("District", 60, 95.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 90, pageWidth - 120, 8, 'FD')

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 103, pageWidth - 20, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("GENERAL INSTRUCTIONS", 14, 108.5, "left")

      doc.setTextColor("#000000")
      doc.text("Objectives:", 12, 116, "left")
      doc.setFont("helvetica", "normal")
      doc.text("The objective of the Employee Performance Appraisal is to measure the employee's performance with regards to the job requirements and the specific knowledge, skills and behaviours that is required for their job.  It is also an avenue to provide clear feedback to the employee on their performance in achieving specific objectives as set by the Company in order to fulfill the company vision, mission and balanced scorecard.   ", 12, 122, { align: "left", maxWidth: pageWidth - 20 })
      doc.setFont("helvetica", "bold")
      doc.text("Instruction:", 12, 138, "left")
      doc.setFont("helvetica", "normal")
      doc.text("        Please use the following rating scale to evaluate the employee's performance", 12, 143.5, "left")

      doc.setTextColor("#FFFFFF")
      doc.setFont("helvetica", "bold")
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 146, 68, 8, 'FD')
      doc.text("RATING SCALE", 15, 151.5, "left")
      doc.setFillColor(168, 168, 168)
      doc.rect(80, 146, pageWidth - 90, 8, 'FD')
      doc.text("PERFORMANCE RATING SCALE DEFINITION", pageWidth - 110, 151.5, "center")
      doc.setFont("helvetica", "normal")
      // 1st row
      doc.setTextColor("#00000")
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 154, 68, 8, 'FD')
      doc.text("6", 45, 159.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 154, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale6_eng}`, 130, 159.5, "center")
      // doc.text("Significantly Exceeds Expectations", 130, 159.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 154, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("EXCEPTIONAL", 235, 164, "center")
      // 2nd row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 162, 68, 8, 'FD')
      doc.text("5", 45, 167.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 162, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale5_eng}`, 130, 167.5, "center")
      // doc.text("Exceeds Expectations", 130, 167.5, "center")
      // doc.rect(183.5, 162, (pageWidth - 90)/2, 8, 'FD')
      // 3rd row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 170, 68, 8, 'FD')
      doc.text("4", 45, 176, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 170, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale4_eng}`, 130, 176, "center")
      // doc.text("Meet all expectations", 130, 176, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 170, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("COMPETENT", 235, 180, "center")
      // 4th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 178, 68, 8, 'FD')
      doc.text("3", 45, 184, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 178, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale3_eng}`, 130, 184, "center")
      // doc.text("Meet some expectations", 130, 184, "center")
      // doc.rect(183.5, 178, (pageWidth - 90)/2, 8, 'FD')
      // 5th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 186, 68, 8, 'FD')
      doc.text("2", 45, 192, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 186, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale2_eng}`, 130, 192, "center")
      // doc.text("Below expectations", 130, 192, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 186, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("BELOW AVERAGE", 235, 196, "center")
      // 6th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 194, 68, 8, 'FD')
      doc.text("1", 45, 199.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 194, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale1_eng}`, 130, 199.5, "center")
      // doc.text("Do Not Meet Expectations", 130, 199.5, "center")
      // employee details
      try {
        doc.text(123, 32, `${this.departmentName}`)
      } catch {
        doc.text(123, 32, `-`)
      }
      try {
        doc.text(113, 39, `${this.myPerformance.start_period}`)
      } catch {
        doc.text(113, 39, `-`)
      }
      try {
        doc.text(175, 39, `${this.myPerformance.end_period}`)
      } catch {
        doc.text(175, 39, `-`)
      }
      try {
        doc.text(130, 63, `${this.empData.employee_number}`)
      } catch {
        doc.text(130, 63, `-`)
      }
      try {
        doc.text(130, 71, `${this.empData.employee_name}`)
      } catch {
        doc.text(130, 71, `-`)
      }
      try {
        doc.text(130, 79, `${this.myPerformance.appraisee.position}`)
      } catch {
        doc.text(130, 79, `-`)
      }
      try {
        doc.text(130, 87, `${this.empData.grade}`)
      } catch {
        doc.text(130, 87, `-`)
      }
      try {
        doc.text(130, 95, `${this.empData.work}`)
      } catch {
        doc.text(130, 95, `-`)
      }
      // doc.rect(183.5, 194, (pageWidth - 90)/2, 8, 'FD')
      // doc.text("This Appraisal Form is applicable to all Top Managements. This form is divided into five (5) parts.", 12, 155, "left")
      // const topMargin = 10
      // doc.setMargins(topMargin)
      doc.addPage("a4")
      doc.text("This Appraisal Form is applicable to all Executive. This form is divided into five (5) parts.", 12, 15, "left")
      doc.text("Part 1 : Key Result Area (KRA's) - Planning Phase: This part is to be completed by the employee and discussed with supervisor.", 12, 25, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("Part 2 : Key Result Area (KRA's) - Performance Review", 12, 35, "left")
      doc.text("           : B.  Key Result Area (KRA's) - Performance Result (Year End) ", 12, 40, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("Part 3 : Competencies Assessment (Divided into two (2) competencies)", 12, 45, "left")
      doc.text("A. Core Competencies - Please Evaluate all three (3) competencies. Select only two (2) most important items of each Core Competencies. These are company's universal competencies which  are applicable to all positions and also direct / indirect to company mission.", 16, 50, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("B. Behavioural Competencies - These are underlying characteristics to empower superior performance on the  job. Each employee must identify the Behavioural Competencies as specified in the Position Competency Matrix for the appraisor to evaluate.", 16, 60, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("Note : All competencies for A and B must follow the Competency Position Matrix.", 12, 70, "left")
      doc.text("Part 4 : A. Training and Development", 12, 75, "left")
      doc.text("           : B. Internal / Industrial Involvement", 12, 80, "left")
      doc.text("Part 5 : Overall Performance Comments - To be completed at the end of performance appraisal period.", 12, 85, "left")
      // doc.text("", 12, 30, "left")
      // doc.text("", 12, 35, "left")

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 90, 110, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("For Human Resources Department Use Only", 15, 95, "left")
      // 1st row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 98, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Date Received", 15, 103.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 98, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 2nd row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 106, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Recorded By", 15, 111.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 106, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 3rd row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 114, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Date", 15, 119.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 114, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 4th row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 122, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Remarks", 15, 127.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 122, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)

      // Summary score table
      const a = this.table.fields.map(x => x.label)

      const b = this.table.scoreList.map(x => [x.keputusan, x.pemberat, x.markah, x.markah_terkumpul, x.markah_manager, x.markah_terkumpul_manager])

      autoTable(doc, {
        startY: 134,
        // tableWidth: pageWidth - 20,
        // margin: { right: 20 },
        headStyles: { halign: 'center', fillColor: [168, 168, 168] },
        columnStyles: {
          1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'center' }, 4: { halign: 'center' }, 5: { halign: 'center' },
        },
        head: [
          a,
        ],
        body: b,
      })

      doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      // Front Page End ----------------------------------------------------------------------------
      doc.addPage("a4", "l")
      doc.setFontSize(12)
      doc.setTextColor(0, 0, 0)

      doc.setFontSize(14)
      doc.setFont("helvetica", "bold")
      doc.text("Key Result Area Details", 20, 20)
      doc.setFont("helvetica", "regular")
      doc.setFontSize(12)

      doc.text("Part 1: This part has three sections which are Performance Planning, Performance Review, and Year End Result.", 20, 30)
      // Part 1 Kra Exec/TM
      try {
      const generateTMData = await this.getKra();

        console.log(">> updateeee", generateTMData);

        const kraHeaders = [
          { header: "Objective Name", dataKey: "objective_name" },
          { header: "KRA Name", dataKey: "kra_name" },
          { header: "Measurement", dataKey: "measurement" },
          { header: "Tracking", dataKey: "tracking" },
          { header: "Mid Year KPI", dataKey: "kpi" },
          { header: "Deadline", dataKey: "deadline" },
          { header: "Self Score", dataKey: "self_score" },
          { header: "Manager Score", dataKey: "manager_score" },
        ];

        const pageWidth = doc.internal.pageSize.getWidth();
        const margins = 20; // Adjust this value to match your page margins
        const availableWidth = pageWidth - 2 * margins;

        doc.autoTable({
          startY: 41,
          head: [kraHeaders.map((col) => col.header)],
          body: generateTMData.map((item) =>
            kraHeaders.map((col) => item[col.dataKey])
          ),
          styles: {
            cellWidth: "wrap",
            minCellHeight: 10,
            halign: "center", // Align text to the center
          },
          headStyles: {
            fillColor: [168, 168, 168], // Header fill color
          },
          columnStyles: {
            0: { cellWidth: availableWidth / kraHeaders.length },
            1: { cellWidth: availableWidth / kraHeaders.length },
            2: { cellWidth: availableWidth / kraHeaders.length },
            3: { cellWidth: availableWidth / kraHeaders.length },
            4: { cellWidth: availableWidth / kraHeaders.length },
            5: { cellWidth: availableWidth / kraHeaders.length },
            6: { cellWidth: availableWidth / kraHeaders.length },
          },
          margin: { left: margins, right: margins },
          tableWidth: "wrap",
        });
        doc.addImage(logo, "png", 285, 192, 7.8125, 13.5, "a", "FAST");
      } catch {
        doc.text(25, 41, `*No Key Result Area`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }
      // Part 1 Kra Exec/TM End -----------------------------------------------------------

      // Part 2a
      doc.addPage("a4", "l")
      doc.setFont("helvetica", "bold")
      doc.text("Part 2: Competency Assessment (divided into two (2) competencies).", 20, 20)
      doc.setFont("helvetica", "regular")
      doc.text("Part 2a: Core Competencies", 20, 30)
      try {
        const generateCoreData = this.getCoreData()

        // eslint-disable-next-line no-inner-declarations
        function createCoreHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 85,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const coreHeaders = createCoreHeaders([
          "subcore",
          "description",
          "self_score",
          "manager_score",
        ])

        const columnWidths = [100]

        doc.table(20, 40, generateCoreData, coreHeaders, columnWidths, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 40, `*No Core Competencies`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 2a End ----------------------------------------------------------------------

      // Part 2b
      doc.addPage("a4", "l")
      doc.text("Part 2b: Behavioral Competencies", 20, 20)

      try {
        const generateBehavData = this.getBehavData()

        // eslint-disable-next-line no-inner-declarations
        function createBehavHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 114,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const BehavHeaders = createBehavHeaders([
          "behavioural_competency",
          "self_score",
          "manager_score",
        ])

        const columnWidthsBehav = [100]

        doc.table(20, 30, generateBehavData, BehavHeaders, columnWidthsBehav, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 30, `*No Behavioral Competencies`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 2b End ----------------------------------------------------------------------

      // Part 3a
      doc.addPage("a4", "l")
      doc.setFont("helvetica", "bold")
      doc.text("Part 3a: Training and Development", 20, 20)
      doc.setFont("helvetica", "regular")

      try {
        const generateTrainingData = this.getTrainingData()

        // eslint-disable-next-line no-inner-declarations
        function createHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 57,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const headers = createHeaders([
          "program_title",
          "start_date",
          "end_date",
          "hours",
          "self_score",
          "manager_score",
        ])

        const columnWidths = [100]
        // console.log('generate data', generateData)
        doc.table(20, 30, generateTrainingData, headers, columnWidths, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 30, `*No Training and Development`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 3a End ------------------------------------------------------------------------------

      // Part 3b
      if (this.internal_industry_status === 'enable') {
        doc.addPage("a4", "p")
        doc.setFont("helvetica", "bold")
        doc.text("Part 3b: Internal / Industrial Involvement", 20, 20)
        doc.setFont("helvetica", "regular")

        try {
          const generateOtherData = this.getOtherContrData()

          // eslint-disable-next-line no-inner-declarations
          function createOtherHeaders(keys) {
            const result = []
            for (let i = 0; i < keys.length; i += 1) {
              result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 65,
                align: "center",
                padding: 0,
              })
            }
            return result
          }

          const otherHeaders = createOtherHeaders([
            "involvement_activities",
            "position",
            "date",
            "self_score",
          ])

          const columnWidths = [100]
          // console.log('generate data', generateData)
          if (this.other_contribution.length !== 0){
            doc.table(7, 30, generateOtherData, otherHeaders, columnWidths, { autoSize: true })
            doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
          } else {
            doc.text(25, 30, `*No Internal / Industrial Involvement`)
            doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
          }
        } catch {
        // doc.setFont("helvetica", "bold")
          doc.text(25, 30, `*No Internal / Industrial Involvement`)
          doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
        }
      }

      // Part 3b End ------------------------------------------------------------------------------

      // Part 4
      doc.addPage("a4", "p")
      doc.setFont("helvetica", "bold")
      doc.text("Part 4: Overall Performance Comments - Please complete at the end of the", 20, 20)
      doc.text("Performance Appraisal.", 20, 27)
      doc.setFont("helvetica", "regular")
      const test = [
        [`COMMENTS BY APPRAISEE`],
        [
          {
            content: `${this.appraisee_comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        [`Full Name: ${this.appraisee_fullname}  |  Position: ${this.appraisee_position}  |  Date: ${this.appraisee_date === null ? '-' : this.appraisee_date.split("T")[0].split("-")[2]}-${this.appraisee_date === null ? '-' : this.appraisee_date.split("T")[0].split("-")[1]}-${this.appraisee_date === null ? '-' : this.appraisee_date.split("T")[0].split("-")[0]}`],
        [`COMMENTS BY APPRAISER`],
        [
          {
            content: `${this.appraiser_comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        [`Full Name: ${this.appraiser_fullname}  |  Position: ${this.appraiser_position}  |  Date: ${this.appraiser_date === null ? '-' : this.appraiser_date.split("T")[0].split("-")[2]}-${this.appraiser_date === null ? '-' : this.appraiser_date.split("T")[0].split("-")[1]}-${this.appraiser_date === null ? '-' : this.appraiser_date.split("T")[0].split("-")[0]}`],
        [`COMMENTS BY APPRAISER'S SUPERVISOR/ HEAD OF DEPARTMENT/ HEAD OF DIVISION/ CEO/ COO/ CHAIRMAN`],
        [
          {
            content: `${this.appraiser_hod_hr_comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        //   [`Full Name: ${this.hod_data === null ? this.appraiser_hr_name : this.appraiser_hod_name}  |  Position: ${this.hod_data === null ? this.appraiser_hr_position : this.appraiser_hod_position}`],
        // ]
        [`Full Name: ${this.hod_data === null ? this.appraiser_hr_name : this.appraiser_hod_name}  |  Position: ${this.hod_data === null ? this.appraiser_hr_position : this.appraiser_hod_position}  |  Date: ${this.appraiser_hod_hr_date === null ? '-' : this.appraiser_hod_hr_date.split("T")[0].split("-")[2]}-${this.appraiser_hod_hr_date === null ? '-' : this.appraiser_hod_hr_date.split("T")[0].split("-")[1]}-${this.appraiser_hod_hr_date === null ? '-' : this.appraiser_hod_hr_date.split("T")[0].split("-")[0]}`],
      ]

      doc.autoTable({
        startY: 35,
        headStyles: { halign: 'center', fillColor: '#000000' },
        styles: {
          cellPadding: 3,
        },
        body: test,
        theme: 'plain',
        margin: {
          left: 20,
          right: 20,
        },
      })

      doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
      // Part 4 End

      // Proposed Training
      doc.addPage("a4", "p")
      doc.setFont("helvetica", "bold")
      doc.text("Proposed Individual Training and Development Needs", 20, 20)
      doc.setFont("helvetica", "regular")
      try {
        doc.text(20, 30, `Supervisor Name: ${this.myPerformance.supervisor.full_name === undefined ? '-' : this.myPerformance.supervisor.full_name}`)
      } catch {
        doc.text(20, 30, `Supervisor Name: -`)
      }
      try {
        doc.text(20, 35, `Position: ${this.myPerformance.supervisor.position === undefined ? '-' : this.myPerformance.supervisor.position}`)
      } catch {
        doc.text(20, 35, `Position: -`)
      }
      const tableDataSuggest = [[`${this.suggestions}`]]

      doc.autoTable({
        startY: 40,
        headStyles: { halign: 'center', fillColor: '#000000' },
        styles: {
          cellPadding: 5,
        },
        body: tableDataSuggest,
        theme: 'grid',
        margin: {
          left: 20,
          right: 20,
        },
      })

      doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
      // Proposed Training End

      doc.save("Employee Performance.pdf")
      // doc.output('dataurlnewwindow', 'newfile.pdf')
    },
    getWeightage() {
      const params = new URLSearchParams()

      params.append(`subsidiary_id`, this.empData.subsidiary_id)

      params.append('role_id', this.empData.grade_set_id)

      const tm = this.empData.grade_set_id === 1 || this.empData.grade_set_id === 2

      // console.log('tm', tm)
      // console.log('set', set)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log('res', response)
        const res = response.data.data
        this.pemberat = {
          KRA: this.empData.category === 'Non Executive' ? `${res.overall_assesment}%` : `${res.key_result_area}%`,
          CoreCompetencies: `${res.core_competency}%`,
          BehaviouralCompetencies: `${res.behavioural_competency}%`,
          TrainingAndDevelopment: tm ? `${res.training + res.self_learning}%` : `${res.training}%`,
          OtherContributions: `${res.internal_industry_involement}%`,
          SummaryScore: '100%',
        }
        // console.log(this.pemberat)
      })
    },
    getScore(set, id) {
      // console.log('set', set)
      this.$axios.get(`${this.$baseUrl}/myperformances/overall_performance?cycle_id=${id}`).then(response => {
        // console.log(response)
        this.overall_total2 = response.data.data.overall_total
        this.overall_total_manager = response.data.data.overall_total_manager
        this.contributionPercent = response.data.data.hasContribution
        const status = response.data.data.internal_industry_status === 'enable'
        // console.log("contribution", this.contributionPercent)
        let finalKRA = ''
        let finalKRAManager = ''
        if (set === 0){
          finalKRA = response.data.data.p1_job_description
          finalKRAManager = response.data.data.p1_job_description_manager
        } else {
          finalKRA = response.data.data.p1_kra
          finalKRAManager = response.data.data.p1_kra_manager
        }
        const { p2_competency_core } = response.data.data
        const { p2_competency_behaviour } = response.data.data
        const { p3_training_development } = response.data.data
        const { p3_contribution } = response.data.data

        const { accumulative1 } = response.data.data
        const { accumulative2 } = response.data.data
        const { accumulative3 } = response.data.data
        const { accumulative4 } = response.data.data
        const { overall_total } = response.data.data

        const { p2_competency_core_manager } = response.data.data
        const { p2_competency_behaviour_manager } = response.data.data
        const { p3_training_development_manager } = response.data.data
        const { p3_contribution_manager } = response.data.data

        const { accumulative1_manager } = response.data.data
        const { accumulative2_manager } = response.data.data
        const { accumulative3_manager } = response.data.data
        const { accumulative4_manager } = response.data.data
        const { overall_total_manager } = response.data.data

        // const employeeRole = this.empData.role

        // let currentRole = ''

        // employeeRole.forEach(x => {
        //   if (x === 2){ // non-exec
        //     // console.log('Non Executive')
        //     if (set === 0) {
        //       currentRole = 'nonExecJD'
        //     } else {
        //       currentRole = 'nonExecKRA'
        //     }
        //   }
        //   if (x === 3){ // exec
        //     // console.log('Executive')
        //     if (this.empData.grade_set === 'Middle Management'){
        //       currentRole = 'topManagement'
        //     } else {
        //       currentRole = 'exec'
        //     }
        //   }
        //   if (x === 4){ // top management
        //     // console.log('Top Management')
        //     currentRole = 'topManagement'
        //   }
        // })

        // const pemberat = {
        //   nonExecKRA: {
        //     KRA: '35%',
        //     CoreCompetencies: '30%',
        //     BehaviouralCompetencies: '25%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '5%',
        //     SummaryScore: '100%',
        //   },
        //   nonExecJD: {
        //     KRA: '60%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '15%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        //   exec: {
        //     KRA: '60%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '15%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        //   topManagement: {
        //     KRA: '50%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '25%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        // }

        const newScoreList = [
          {
            keputusan: this.kra === 0 ? `${this.$t('Overall Assessment')}` : `${this.$t('Key Result Area')}`, pemberat: this.pemberat.KRA, markah: finalKRA.toString(), markah_terkumpul: accumulative1.toString(), markah_manager: finalKRAManager.toString(), markah_terkumpul_manager: accumulative1_manager.toString(),
          },
          {
            keputusan: `${this.$t('Core Competencies')}`, pemberat: this.pemberat.CoreCompetencies, markah: p2_competency_core.toString(), markah_terkumpul: accumulative2.toString(), markah_manager: p2_competency_core_manager.toString(), markah_terkumpul_manager: accumulative2_manager.toString(),
          },
          {
            keputusan: `${this.$t('Behavioural Competencies')}`, pemberat: this.pemberat.BehaviouralCompetencies, markah: p2_competency_behaviour.toString(), markah_terkumpul: accumulative3.toString(), markah_manager: p2_competency_behaviour_manager.toString(), markah_terkumpul_manager: accumulative3_manager.toString(),
          },
          {
            keputusan: `${this.$t('Training and Development')}`, pemberat: this.pemberat.TrainingAndDevelopment, markah: p3_training_development.toString(), markah_terkumpul: accumulative4.toString(), markah_manager: p3_training_development_manager.toString(), markah_terkumpul_manager: accumulative4_manager.toString(),
          },
          // {
          //   keputusan: `${this.$t('Internal / Industrial Involvement')}`, pemberat: pemberat[currentRole].OtherContributions, markah: p3_contribution.toString(), markah_terkumpul: overall_total.toString(), markah_manager: p3_contribution_manager.toString(), markah_terkumpul_manager: accumulative4_manager.toString(),
          // },
          {
            keputusan: `${this.$t('Summary Score (%)')}`, pemberat: this.pemberat.SummaryScore, markah: overall_total.toString(), markah_terkumpul: '', markah_manager: overall_total_manager.toString(), markah_terkumpul_manager: '',
          },
        ]

        // this.table.scoreList = newScoreList
        const contr = {
          keputusan: 'Internal / Industrial Involvement', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
        }

        if (status){
          // this.table.scoreList = newScoreList.slice(3) + contr + newScoreList[4]
          this.table.scoreList = newScoreList.slice(0, 4).concat(contr).concat(newScoreList.slice(4))
        } else {
          this.table.scoreList = newScoreList
        }

        // eslint-disable-next-line camelcase
        this.contribution = p3_contribution
        this.show = false
        // this.autoTable()
        // return this.table.scoreList

        // trigger generate report
        // this.triggerGenerateReport()
        // console.log("checkscore", this.table.scoreList)
      })
        .catch(() => {
          this.show = false
        })
    },

  },
}
</script>
<style>
.styleFont {
    font-size: 2.5rem !important;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-size: 1.5rem;
    position: static;
}

</style>
