<template>
  <div>
    <div
      class="modalScroll d-flex flex-column align-items-center"
      style="width: auto; height: auto"
    >

      <div
        class="p-1"
        style=""
      >
        <button
          class="btn btn-close mb-1 saj-subtitle"
          aria-label="Close modal"
          style="
          color: white;
          background: #ff0000;
        "
          @click="downloadPerformancePDF(empData), getScore(empData.jd_kra_status, empData.performance_id, empData.user_id)"
        >
          {{ $t("Download PDF") }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import {

} from "bootstrap-vue"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  props: {
    empData: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      businessUnitList: [],
      allbusinessUnit: [],
      subsidiary_name: null,
      departmentName: null,
      myPerformance: null,
      training_development: [],
      other_contribution: [],
      active_competency_core_performance: [],
      active_competency_behaviour_performance: [],
      main_task: [],
      side_task: [],
      user_kra_jd_status: null,
      allUserData: null,
      // Data for summary score
      kra_id: null,
      contribution: null,
      contributionPercent: 0,
      contributionPercentTeam: 0,
      scoreYearSelected: "a",
      scoreYearoptions: [
        { value: "a", text: "2022" },
        { value: "b", text: "2021" },
        { value: "c", text: "2020" },
      ],
      overall_total2: "",
      overall_total_manager: "",
      table: {
        // user score
        p1_kra: 0,
        p1_job_description: 0,
        p2_competency_core: 0,
        p2_competency_behaviour: 0,
        p3_training_development: 0,
        p3_contribution: 0,
        accumulative1: 0,
        accumulative2: 0,
        accumulative3: 0,
        accumulative4: 0,
        overall_total: 0,
        // manager score
        p1_kra_manager: 0,
        p1_job_description_manager: 0,
        p2_competency_core_manager: 0,
        p2_competency_behaviour_manager: 0,
        p3_training_development_manager: 0,
        p3_contribution_manager: 0,
        accumulative1_manager: 0,
        accumulative2_manager: 0,
        accumulative3_manager: 0,
        accumulative4_manager: 0,
        overall_total_manager: 0,
        bordered: true,

        scoreList: [
          {
            keputusan: 'Key Result Area', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Core Competencies', pemberat: '%', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Behavioural Competencies', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Training and Development', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Penglibatan Dalaman / Industri', pemberat: '', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
          {
            keputusan: 'Summary Score (%)', markah: '', markah_terkumpul: '', markah_manager: '', markah_terkumpul_manager: '',
          },
        ],
        fields: [
          {
            key: 'keputusansss', label: `${this.$t('Keputusan')}`, thClass: 'text-center',
          },
          {
            key: 'pemberattt', label: `${this.$t('Pemberat')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah', label: `${this.$t('Markah')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul', label: `${this.$t('Markah Terkumpul')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_manager', label: `${this.$t('Skor Pengurus')}`, thClass: 'text-center', tdClass: 'text-center',
          },
          {
            key: 'markah_terkumpul_manager', label: `${this.$t('Skor Terkumpul Pengurus')}`, thClass: 'text-center', tdClass: 'text-center',
          },

        ],

      },
      scaleList: [],
      scale1_bm: [],
      scale2_bm: [],
      scale3_bm: [],
      scale4_bm: [],
      scale5_bm: [],
      scale6_bm: [],
      internal_industry_status: null,
      suggestions: null,
      hod_data: null,
      appraiser_hod_name: null,
      appraiser_hod_position: null,
      appraiser_hr_name: null,
      appraiser_hr_position: null,
      pemberat: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    this.getSubsidiaryById()
    this.getDepartmentById()
    this.scoreRating()
    this.getWeightage()
  },
  methods: {
    getSubsidiaryById(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getById?id=${this.empData.subsidiary_id}`).then(response => {
        this.allbusinessUnit = response.data.data
        this.allbusinessUnit.forEach(b => {
          this.businessUnitList.push({
            text: b.subsidiary_name,
            value: b.id,

          })
        })
        this.subsidiary_name = this.allbusinessUnit.map(x => (x.subsidiary_name))
        // console.log(this.subsidiary_name)
      })
    },
    getDepartmentById(){
      this.$axios.get(`${this.$baseUrl}/departments/get_by_department_id?department_id=${this.empData.department_id}`).then(response => {
        this.departmentName = response.data.data.name
      })
    },
    scoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.scaleList = response.data.data
        this.scale1_bm = this.scaleList[5].definition_my
        this.scale2_bm = this.scaleList[4].definition_my
        this.scale3_bm = this.scaleList[3].definition_my
        this.scale4_bm = this.scaleList[2].definition_my
        this.scale5_bm = this.scaleList[1].definition_my
        this.scale6_bm = this.scaleList[0].definition_my
      })
    },
    downloadPerformancePDF(empData){
      // console.log('userId & perf id', empData.user_id, empData.performance_id)
      this.$axios.get(`${this.$baseUrl}/download_pdf_data?user_id=${empData.user_id}&performance_id=${empData.performance_id}`).then(response => {
        // console.log('res pdf', response)
        this.myPerformance = response.data.data
        this.triggerGenerateReport()
        this.user_kra_jd_status = this.myPerformance.user_kra_jd_status
        this.main_task = this.myPerformance.main_task
        this.side_task = this.myPerformance.side_task
        this.active_competency_core_performance = this.myPerformance.active_competency_core_performance
        this.active_competency_behaviour_performance = this.myPerformance.active_competency_behaviour_performance
        this.training_development = this.myPerformance.training_development
        this.other_contribution = this.myPerformance.other_contribution
        this.internal_industry_status = this.myPerformance.internal_industry_status
        // part 4 comment
        this.hod_data = response.data.data.appraiser_sv_hod
        if (this.hod_data !== null) {
          this.appraiser_hod_name = this.hod_data.full_name
          this.appraiser_hod_position = this.hod_data.position
        }
        this.appraiser_hr_name = response.data.data.appraiser_hod_hr.full_name
        this.appraiser_hr_position = response.data.data.appraiser_hod_hr.position
        // suggestion
        if (this.myPerformance.suggestions.length === 0) {
          this.suggestions = '-'
        } else {
          this.suggestions = this.myPerformance.suggestions[0].suggestion
        }
        this.$emit('close')
      })
    },
    triggerGenerateReport() {
      this.showPerformance()
    },
    getImage(url){
      return new Promise(resolve => {
        const image = new Image()
        image.setAttribute('crossOrigin', 'anonymous') // getting images from external domain

        // eslint-disable-next-line func-names
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = this.naturalWidth
          canvas.height = this.naturalHeight

          // next three lines for white background in case png has a transparent background
          const ctx = canvas.getContext('2d')
          ctx.fillStyle = '#fff' /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height)

          canvas.getContext('2d').drawImage(this, 0, 0)

          resolve(canvas.toDataURL('image/jpeg'))
        }

        image.src = url
      })
    },
    getMainTask(){
      if (this.main_task !== null){
        const myMainTask = this.main_task.map(x => ({
          tugas_utama: x.main_task_field === null ? '-' : x.main_task_field,
          sasaran: x.target.toString() === null ? '-' : x.target.toString(),
          pencapaian_tahunan: x.yearly_achievement.toString() === null ? '-' : x.yearly_achievement.toString(),
          skor_sendiri: x.marks.toString() === null ? '-' : x.marks.toString(),
          skor_pengurus: x.manager_marks === null ? '-' : x.manager_marks.toString(),

        }))
        return myMainTask
      }
      return null
    },
    getSideTask(){
      if (this.side_task !== null){
        const mySideTask = this.side_task.map(x => ({
          tugas_tambahan: x.side_task_summary.toString() === null ? '-' : x.side_task_summary.toString(),
          sasaran: x.target === null ? '-' : x.target,
          pencapaian_tahunan: x.yearly_achievement.toString() === null ? '-' : x.yearly_achievement.toString(),
          skor_sendiri: x.marks.toString() === null ? '-' : x.marks.toString(),
          skor_pengurus: x.manager_marks === null ? '-' : x.manager_marks.toString(),

        }))
        return mySideTask
      }
      return null
    },
    getCoreData(){
      if (this.active_competency_core_performance !== null){
        const myCore = this.active_competency_core_performance.map(x => ({
          subteras: x.subcore.name.toString(),
          penerangan: x.subcore.description.toString(),
          skor_sendiri: x.self_score.toString(),
          skor_pengurus: x.manager_score.toString(),

        }))
        // console.log('core', myCore)
        return myCore
      }
      return null
    },
    getBehavData(){
      if (this.active_competency_behaviour_performance !== null){
        const myBehav = this.active_competency_behaviour_performance.map(x => ({
          tingkah_laku: x.behaviour.name.toString(),
          skor_sendiri: x.self_score.toString(),
          skor_pengurus: x.manager_score.toString(),

        }))
        return myBehav
      }
      return null
    },
    getTrainingData() {
      if (this.training_development !== null){
        const myPerf = this.training_development.map(x => ({
          tajuk_program: x.program_name.toString(),
          tarikh_mula: x.start_date.toString(),
          tarikh_tamat: x.end_date.toString(),
          jumlah_jam: x.hour.toString(),
          skor_sendiri: x.self_score.toString(),
          skor_pengurus: x.manager_score.toString(),
        }))

        return myPerf
      }
      return null
    },
    getOtherContrData() {
      if (this.other_contribution !== null){
        const myOther = this.other_contribution.map(x => ({
          penglibatan_aktiviti: x.involvement.toString(),
          jawatan: x.position_bahasa.toString(),
          tarikh: x.date.toString(),
          skor_sendiri: x.self_score.toString(),
        }))

        return myOther
      }
      return null
    },
    async showPerformance(){
      // eslint-disable-next-line new-cap
      const doc = new jsPDF("landscape", "mm", "a4")
      // const width = doc.internal.pageSize.getWidth()
      const logo = await this.getImage("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Ranhill_Utilities_Berhad_Logo_%28Original%29.png/922px-Ranhill_Utilities_Berhad_Logo_%28Original%29.png?20211125033701")
      doc.addImage(logo, 'png', 20, 10, 18.8125, 27.5, 'a', 'FAST')

      // Front Page
      const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()

      doc.setFont("helvetica", "bold")
      doc.setFontSize(14)
      doc.text(`${this.subsidiary_name}`, 150, 12, null, null, "center")
      doc.setFontSize(12)
      doc.text("BORANG PENILAIAN PRESTASI", 150, 19, null, null, "center")
      doc.setFontSize(11)
      doc.text("Perkeranian / Pembantu / Pegawai Khidmat Pelanggan / Operator Loji / Krew / Pembantu Lapangan", 150, 25, null, null, "center")
      doc.text("JABATAN                :______________________________________", 200, 33, null, null, "right")
      doc.text("TEMPOH PENILAIAN  :____________________(bln/thn) hingga ____________________(bln/thn)", 236, 40, null, null, "right")

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 50, pageWidth - 20, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("MAKLUMAT ANGGOTA", 150, 55.5, "center")
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 58, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Nombor Anggota", 60, 64, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 58, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 66, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Nama Anggota", 60, 72, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 66, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 74, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Jawatan", 60, 79.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 74, pageWidth - 120, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 82, 100, 8, 'FD')
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 82, pageWidth - 120, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Gred Jawatan", 60, 87, "center")
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 90, 100, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Daerah", 60, 95.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(110, 90, pageWidth - 120, 8, 'FD')

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 103, pageWidth - 20, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("ARAHAN AM", 14, 108.5, "left")

      doc.setTextColor("#000000")
      doc.text("Objektif:", 12, 116, "left")
      doc.setFont("helvetica", "normal")
      doc.text("Objektif utama Penilaian Prestasi ini adalah mengukur prestasi anggota yang berkaitan dengan keperluan-keperluan tugas dan kemahiran-kemahiran dan pengetahuan yang khusus dan juga kelakuan yang berkaitan dengan tugas. Ia juga satu cara untuk menyediakan maklumbalas yang jelas kepada anggota mengenai prestasi mereka di dalam mencapai objektif-objektif khusus yang telah ditetapkan oleh Syarikat di dalam mencapai visi, misi dan balanced scorecard.   ", 12, 122, { align: "left", maxWidth: pageWidth - 20 })
      doc.setFont("helvetica", "bold")
      doc.text("Arahan:", 12, 138, "left")
      doc.setFont("helvetica", "normal")
      doc.text("       Sila gunakan Skala Penilaian di bawah untuk menilai Prestasi Anggota", 12, 143.5, "left")

      doc.setTextColor("#FFFFFF")
      doc.setFont("helvetica", "bold")
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 146, 68, 8, 'FD')
      doc.text("SKALA", 15, 151.5, "left")
      doc.setFillColor(168, 168, 168)
      doc.rect(80, 146, pageWidth - 90, 8, 'FD')
      doc.text("TAKRIFAN SKALA PENILAIAN PRESTASI", pageWidth - 110, 151.5, "center")
      doc.setFont("helvetica", "normal")
      // 1st row
      doc.setTextColor("#00000")
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 154, 68, 8, 'FD')
      doc.text("6", 45, 159.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 154, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale6_bm}`, 130, 159.5, "center")
      // doc.text("Kesemua Pencapaian Melebihi Sasaran", 130, 159.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 154, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("Melebihi Sasaran", 235, 164, "center")
      // 2nd row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 162, 68, 8, 'FD')
      doc.text("5", 45, 167.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 162, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale5_bm}`, 130, 167.5, "center")
      // doc.text("Sebahagian Pencapaian Melebihi Sasaran", 130, 167.5, "center")
      // doc.rect(183.5, 162, (pageWidth - 90)/2, 8, 'FD')
      // 3rd row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 170, 68, 8, 'FD')
      doc.text("4", 45, 176, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 170, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale4_bm}`, 130, 176, "center")
      // doc.text("Mencapai Semua Sasaran", 130, 176, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 170, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("Mencapai Sasaran", 235, 180, "center")
      // 4th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 178, 68, 8, 'FD')
      doc.text("3", 45, 184, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 178, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale3_bm}`, 130, 184, "center")
      // doc.text("Mencapai Sebahagian Sasaran", 130, 184, "center")
      // doc.rect(183.5, 178, (pageWidth - 90)/2, 8, 'FD')
      // 5th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 186, 68, 8, 'FD')
      doc.text("2", 45, 192, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 186, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale2_bm}`, 130, 192, "center")
      // doc.text("Hampir Mencapai Sasaran", 130, 192, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(183.5, 186, (pageWidth - 90) / 2, 16, 'FD')
      doc.text("Bawah Sasaran", 235, 196, "center")
      // 6th row
      doc.setFillColor(168, 168, 168)
      doc.rect(12, 194, 68, 8, 'FD')
      doc.text("1", 45, 199.5, "center")
      doc.setFillColor(255, 255, 255)
      doc.rect(80, 194, (pageWidth - 90) / 2, 8, 'FD')
      doc.text(`${this.scale1_bm}`, 130, 199.5, "center")
      // doc.text("Tidak Mencapai Sasaran", 130, 199.5, "center")
      // employee details
      try {
        doc.text(123, 32, `${this.departmentName}`)
      } catch {
        doc.text(123, 32, `-`)
      }
      try {
        doc.text(115, 39, `${this.myPerformance.start_period_my}`)
      } catch {
        doc.text(115, 39, `-`)
      }
      try {
        doc.text(186, 39, `${this.myPerformance.end_period_my}`)
      } catch {
        doc.text(186, 39, `-`)
      }
      try {
        doc.text(130, 63, `${this.empData.employee_number}`)
      } catch {
        doc.text(130, 63, `-`)
      }
      try {
        doc.text(130, 71, `${this.empData.employee_name}`)
      } catch {
        doc.text(130, 71, `-`)
      }
      try {
        doc.text(130, 79, `${this.myPerformance.appraisee.position}`)
      } catch {
        doc.text(130, 79, `-`)
      }
      try {
        doc.text(130, 87, `${this.empData.grade}`)
      } catch {
        doc.text(130, 87, `-`)
      }
      try {
        doc.text(130, 95, `${this.empData.work}`)
      } catch {
        doc.text(130, 95, `-`)
      }
      // doc.rect(183.5, 194, (pageWidth - 90)/2, 8, 'FD')
      // doc.text("Borang ini terpakai kepada semua anggota Bukan Eksekutif. Borang ini terbahagi kepada empat (4) bahagian.", 12, 155, "left")

      doc.addPage("a4")
      doc.text("Borang ini terpakai kepada semua anggota Bukan Eksekutif. Borang ini terbahagi kepada empat (4) bahagian.", 12, 15, "left")
      doc.text("Bahagian 1 : Penilaian Tugas Keseluruhan. Dilengkapkan oleh anggota dan berbincang dengan Penilai.", 12, 25, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("Bahagian 2 : A : Kompetensi Teras - Sila nilai ketiga-tiga kompetensi dan pilih dua (2) item utama bagi setiap kompetensi tersebut. Kompetensi ini adalah asas / nilai syarikat yang perlu dimiliki oleh semua anggota dalam semua jawatan.", 12, 35, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("           : B : Kompetensi Tingkahlaku  - Sila nilai Kompetensi Tingkahlaku ini seperti yang dinyatakan di dalam Matriks Kompetensi Jawatan. Ia merupakan sifat / ciri yang diperlukan untuk menghasilkan prestasi kerja yang lebih baik.", 12, 45, { align: "left", maxWidth: pageWidth - 20 })
      // doc.text("Part 3 : Competencies Assessment (Divided into two (2) competencies)", 12, 55, "left")
      doc.text("Bahagian 3A : Latihan dan Pembangunan", 12, 60, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("             3B : Penglibatan Dalaman / Industri", 16, 65, { align: "left", maxWidth: pageWidth - 20 })
      doc.text("Nota : Sila nilai A dan B berdasarkan Matriks Kompetensi Jawatan.", 12, 70, "left")
      doc.text("Bahagian 4 : Komen Kesuluruhan Prestasi - Sila lengkapkan pada penghujung Penilaian Prestasi.", 12, 75, "left")
      // doc.text("           : B. Other Contributions", 12, 80, "left")
      // doc.text("Part 5 : Overall Performance Comments - To be completed at the end of performance appraisal period.", 12, 85, "left")
      // doc.text("", 12, 30, "left")
      // doc.text("", 12, 35, "left")

      doc.setFillColor(168, 168, 168)
      doc.rect(10, 85, 110, 8, 'FD')
      doc.setTextColor("#FFFFFF")
      doc.text("Untuk Kegunaan Sumber Manusia Sahaja", 15, 90, "left")
      // 1st row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 93, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Tarikh Terima", 15, 98.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 93, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 2nd row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 101, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Direkod Oleh", 15, 106.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 101, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 3rd row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 109, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Tarikh Rekod", 15, 114.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 109, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // 4th row
      doc.setFillColor(168, 168, 168)
      doc.rect(10, 117, 60, 8, 'FD')
      doc.setTextColor("#00000")
      doc.text("Komen", 15, 122.5, "left")
      doc.setFillColor(255, 255, 255)
      doc.rect(70, 117, 50, 8, 'FD')
      doc.setFillColor(168, 168, 168)
      // Summary score table
      const a = this.table.fields.map(x => x.label)

      const b = this.table.scoreList.map(x => [x.keputusan, x.pemberat, x.markah, x.markah_terkumpul, x.markah_manager, x.markah_terkumpul_manager])

      // console.log("jcna", b)
      // console.log('a', result)
      autoTable(doc, {
        startY: 132,
        // tableWidth: pageWidth - 20,
        // margin: { right: 20 },
        headStyles: { halign: 'center', fillColor: [168, 168, 168] },
        columnStyles: {
          1: { halign: 'center' }, 2: { halign: 'center' }, 3: { halign: 'center' }, 4: { halign: 'center' }, 5: { halign: 'center' },
        },
        head: [
          a,
        ],
        body: b,
      })
      doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')

      // Front Page End --------------------------------------------------

      if (this.user_kra_jd_status === 1){
        doc.addPage("a4", "l")
        doc.setFontSize(12)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(14)
        doc.setFont("helvetica", "bold")
        doc.text("Butiran Bidang Kerja Utama", 20, 20)
        doc.setFont("helvetica", "regular")
        doc.setFontSize(12)
        // Part 1 Non-Exec KRA
        doc.text("Bahagian 1: Bahagian ini mengandungi dua seksyen iaitu Tugas Utama dan Tugas Tambahan.", 20, 30)
        doc.text("Tugas Utama:", 20, 45)

        // *Main Task ---------------------------------------------------
        try {
          const generateMainData = this.getMainTask()

          // eslint-disable-next-line no-inner-declarations
          function createMainHeaders(keys) {
            const result = []
            for (let i = 0; i < keys.length; i += 1) {
              result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 68,
                align: "center",
                padding: 0,
              })
            }
            return result
          }

          const MainHeaders = createMainHeaders([
            "tugas_utama",
            "sasaran",
            "pencapaian_tahunan",
            "skor_sendiri",
            "skor_pengurus",
          ])

          const columnWidths = [70]

          // console.log('generate main data', generateMainData)

          doc.table(20, 55, generateMainData, MainHeaders, columnWidths, { autoSize: true })
          doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
        } catch {
          doc.text(25, 55, `*Tiada Tugas Utama`)
          doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
        }

        // *Additional Task ----------------------------------------------
        doc.addPage("a4", "l")
        doc.text("Ringkasan tugas tambahan yang dijalankan sepanjang tahun:", 20, 20)

        try {
          const generateSideData = this.getSideTask()

          // eslint-disable-next-line no-inner-declarations
          function createSideHeaders(keys) {
            const result = []
            for (let i = 0; i < keys.length; i += 1) {
              result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 68,
                align: "center",
                padding: 0,
              })
            }
            return result
          }

          const SideHeaders = createSideHeaders([
            "tugas_tambahan",
            "sasaran",
            "pencapaian_tahunan",
            "skor_sendiri",
            "skor_pengurus",
          ])

          const columnWidths = [70]

          // console.log('generate side data', generateSideData)

          doc.table(20, 30, generateSideData, SideHeaders, columnWidths, { autoSize: true })
          doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
        } catch {
          doc.text(25, 30, `*Tiada Tugas Tambahan`)
          doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
        }
      // Part 1 Non-Exec KRA End----------------------------------------------------------------
      } else {
        // Part 1 Non-Exec JD
        // Main Task JD
        // console.log('jd', this.myPerformance)
        if (this.myPerformance.job_description !== null) {
          doc.addPage("a4", "p")
          doc.setFontSize(12)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(14)
          doc.setFont("helvetica", "bold")
          doc.text("Butiran Senarai Tugas", 20, 20)
          doc.setFont("helvetica", "regular")
          doc.setFontSize(12)
          doc.text("Bahagian 1: Penilaian Tugas Keseluruhan. Dilengkapkan oleh anggota dan berbincang", 20, 30)
          doc.text("dengan Penilai.", 20, 35)
          doc.text("Ringkasan tugasan yang dilaksanakan sepanjang tahun:", 20, 45)

          doc.text("Skor Sendiri:", 20, 55)
          try {
            doc.text(45, 55, `${this.myPerformance.job_description.task_score}`)
          } catch {
            doc.text(45, 55, `-`)
          }
          doc.text("Skor Pengurus:", 20, 60)
          try {
            doc.text(49, 60, `${this.myPerformance.job_description.manager_score}`)
          } catch {
            doc.text(49, 60, `-`)
          }

          const tableDataMain = [[`${(this.myPerformance.job_description === undefined || this.myPerformance.job_description.job_task === null) ? '-' : this.myPerformance.job_description.job_task}`]]

          doc.autoTable({
            startY: 65,
            headStyles: { halign: 'center', fillColor: '#000000' },
            styles: {
              cellPadding: 5,
            },
            body: tableDataMain,
            theme: 'grid',
            margin: {
              left: 20,
              right: 20,
            },
          })

          doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')

          // Additional Task JD
          doc.addPage("a4", "p")
          doc.text("Ringkasan tugasan tambahan yang dilaksanakan sepanjang tahun / tugas-tugas lain", 20, 30)
          doc.text("yang diarahkan dari masa ke semasa:", 20, 35)

          doc.text("Skor Sendiri:", 20, 45)
          try {
            doc.text(45, 45, `${this.myPerformance.job_description.subtask_score}`)
          } catch {
            doc.text(45, 45, `-`)
          }
          doc.text("Skor Pengurus:", 20, 50)
          try {
            doc.text(49, 50, `${this.myPerformance.job_description.manager_subscore}`)
          } catch {
            doc.text(49, 50, `-`)
          }

          const tableDataAdd = [[`${(this.myPerformance.job_description === undefined || this.myPerformance.job_description.job_subtask === null) ? '-' : this.myPerformance.job_description.job_subtask}`]]

          doc.autoTable({
            startY: 55,
            headStyles: { halign: 'center', fillColor: '#000000' },
            styles: {
              cellPadding: 5,
            },
            body: tableDataAdd,
            theme: 'grid',
            margin: {
              left: 20,
              right: 20,
            },
          })
        } else {
          doc.addPage("a4", "p")
          doc.setFontSize(12)
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(14)
          doc.setFont("helvetica", "bold")
          doc.text("Bahagian 1: Butiran Senarai Tugas", 20, 20)
          doc.setFont("helvetica", "regular")
          doc.setFontSize(12)
          doc.text("*Tiada Butiran Senarai Tugas", 20, 30)
        }

        doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
      }
      // Part 1 End -----------------------------------------------------------------------------------

      // Part 2a
      doc.addPage("a4", "l")
      doc.setFont("helvetica", "bold")
      doc.text("Bahagian 2: Penilaian Kompetensi (terbahagi kepada dua (2) kompetensi).", 20, 20)
      doc.setFont("helvetica", "regular")
      doc.text("Bahagian 2a: Kompetensi Teras", 20, 30)
      try {
        const generateCoreData = this.getCoreData()

        // eslint-disable-next-line no-inner-declarations
        function createCoreHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 85,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const coreHeaders = createCoreHeaders([
          "subteras",
          "penerangan",
          "skor_sendiri",
          "skor_pengurus",
        ])

        const columnWidths = [100]

        doc.table(20, 40, generateCoreData, coreHeaders, columnWidths, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 40, `*Tiada Kompetensi Teras`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 2a End ----------------------------------------------------------------------

      // Part 2b
      doc.addPage("a4", "l")
      doc.text("Bahagian 2b: Kompetensi Tingkahlaku", 20, 20)

      try {
        const generateBehavData = this.getBehavData()

        // eslint-disable-next-line no-inner-declarations
        function createBehavHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 114,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const BehavHeaders = createBehavHeaders([
          "tingkah_laku",
          "skor_sendiri",
          "skor_pengurus",
        ])

        const columnWidthsBehav = [100]

        doc.table(20, 30, generateBehavData, BehavHeaders, columnWidthsBehav, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 30, `*Tiada Kompetensi Tingkahlaku`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 2b End ----------------------------------------------------------------------

      // Part 3a
      doc.addPage("a4", "l")
      doc.setFont("helvetica", "bold")
      doc.text("Bahagian 3a: Latihan dan Pembangunan", 20, 20)
      doc.setFont("helvetica", "regular")

      try {
        const generateTrainingData = this.getTrainingData()

        // eslint-disable-next-line no-inner-declarations
        function createHeaders(keys) {
          const result = []
          for (let i = 0; i < keys.length; i += 1) {
            result.push({
              id: keys[i],
              name: keys[i],
              prompt: keys[i],
              width: 57,
              align: "center",
              padding: 0,
            })
          }
          return result
        }

        const headers = createHeaders([
          "tajuk_program",
          "tarikh_mula",
          "tarikh_tamat",
          "jumlah_jam",
          "skor_sendiri",
          "skor_pengurus",
        ])

        const columnWidths = [100]
        // console.log('generate data', generateData)
        doc.table(20, 30, generateTrainingData, headers, columnWidths, { autoSize: true })
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      } catch {
        doc.text(25, 30, `*Tiada Latihan dan Pembangunan`)
        doc.addImage(logo, 'png', 285, 192, 7.8125, 13.5, 'a', 'FAST')
      }

      // Part 3a End ------------------------------------------------------------------------------

      // Part 3b
      if (this.internal_industry_status === 'enable') {
        doc.addPage("a4", "p")
        doc.setFont("helvetica", "bold")
        doc.text("Bahagian 3b: Penglibatan Dalaman / Industri", 20, 20)
        doc.setFont("helvetica", "regular")

        try {
          const generateOtherData = this.getOtherContrData()

          // eslint-disable-next-line no-inner-declarations
          function createOtherHeaders(keys) {
            const result = []
            for (let i = 0; i < keys.length; i += 1) {
              result.push({
                id: keys[i],
                name: keys[i],
                prompt: keys[i],
                width: 65,
                align: "center",
                padding: 0,
              })
            }
            return result
          }

          const otherHeaders = createOtherHeaders([
            "penglibatan_aktiviti",
            "jawatan",
            "tarikh",
            "skor_sendiri",
          ])

          const columnWidths = [100]
          // console.log('generate data', generateData)
          if (this.other_contribution.length !== 0){
            doc.table(7, 30, generateOtherData, otherHeaders, columnWidths, { autoSize: true })
            doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
          } else {
            doc.text(25, 30, `*Tiada Penglibatan Dalaman / Industri`)
            doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
          }
        } catch {
        // doc.setFont("helvetica", "bold")
          doc.text(25, 30, `*Tiada Penglibatan Dalaman / Industri`)
          doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
        }
      }

      // Part 3b End ------------------------------------------------------------------------------

      // Part 4
      doc.addPage("a4", "p")
      doc.setFont("helvetica", "bold")
      doc.text("Bahagian 4: Komen Keseluruhan Prestasi - Sila lengkapkan pada akhir", 20, 20)
      doc.text("Penilaian Prestasi.", 20, 27)
      doc.setFont("helvetica", "regular")
      const test = [
        [`KOMEN ANGGOTA`],
        [
          {
            content: `${this.myPerformance.comment_performance === null || this.myPerformance.comment_performance.comment === null ? '-' : this.myPerformance.comment_performance.comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        [`Nama Anggota: ${this.myPerformance.appraisee.full_name === undefined ? '-' : this.myPerformance.appraisee.full_name}  |  Jawatan: ${this.myPerformance.appraisee.position === undefined ? '-' : this.myPerformance.appraisee.position}  |  Tarikh: ${this.myPerformance.comment_performance === null ? '-' : this.myPerformance.comment_performance.updated_at.split("T")[0].split("-")[2]}-${this.myPerformance.comment_performance === null ? '-' : this.myPerformance.comment_performance.updated_at.split("T")[0].split("-")[1]}-${this.myPerformance.comment_performance === null ? '-' : this.myPerformance.comment_performance.updated_at.split("T")[0].split("-")[0]}`],
        [`KOMEN PENILAI`],
        [
          {
            content: `${this.myPerformance.appraiser_comment === null ? '-' : this.myPerformance.appraiser_comment.comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        [`Nama Penilai: ${this.myPerformance.supervisor === null ? '-' : this.myPerformance.supervisor.full_name}  |  Jawatan: ${this.myPerformance.supervisor === null ? '-' : this.myPerformance.supervisor.position}  |  Tarikh: ${this.myPerformance.appraiser_comment === null ? '-' : this.myPerformance.appraiser_comment.updated_at.split("T")[0].split("-")[2]}-${this.myPerformance.appraiser_comment === null ? '-' : this.myPerformance.appraiser_comment.updated_at.split("T")[0].split("-")[1]}-${this.myPerformance.appraiser_comment === null ? '-' : this.myPerformance.appraiser_comment.updated_at.split("T")[0].split("-")[0]}`],
        [`KOMEN OLEH PENILAI PENYELIA/ KETUA JABATAN/ KETUA BAHAGIAN/ CEO/ COO/ PENGERUSI`],
        [
          {
            content: `${this.myPerformance.appraiser_sv_hod_comment === null ? '-' : this.myPerformance.appraiser_sv_hod_comment.comment}`,
            styles: {
              lineWidth: 0.5,
            },
          },
        ],
        [`Nama Penyelia Penilai: ${this.hod_data === null ? this.appraiser_hr_name : this.appraiser_hod_name}  |  Jawatan: ${this.hod_data === null ? this.appraiser_hr_position : this.appraiser_hod_position}  |  Tarikh: ${this.myPerformance.appraiser_sv_hod_comment === null ? '-' : this.myPerformance.appraiser_sv_hod_comment.updated_at.split("T")[0].split("-")[2]}-${this.myPerformance.appraiser_sv_hod_comment === null ? '-' : this.myPerformance.appraiser_sv_hod_comment.updated_at.split("T")[0].split("-")[1]}-${this.myPerformance.appraiser_sv_hod_comment === null ? '-' : this.myPerformance.appraiser_sv_hod_comment.updated_at.split("T")[0].split("-")[0]}`],
      ]

      doc.autoTable({
        startY: 35,
        headStyles: { halign: 'center', fillColor: '#000000' },
        styles: {
          cellPadding: 3,
        },
        body: test,
        theme: 'plain',
        margin: {
          left: 20,
          right: 20,
        },
      })

      doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
      // Part 4 End

      // Proposed Training
      doc.addPage("a4", "p")
      doc.setFont("helvetica", "bold")
      doc.text("Cadangan Keperluan Latihan Dan Pembangunan Individu", 20, 20)
      doc.setFont("helvetica", "regular")
      try {
        doc.text(20, 30, `Nama Penilai: ${this.myPerformance.supervisor.full_name === undefined ? '-' : this.myPerformance.supervisor.full_name}`)
      } catch {
        doc.text(20, 30, `Nama Penilai: -`)
      }
      try {
        doc.text(20, 35, `Jawatan: ${this.myPerformance.supervisor.position === undefined ? '-' : this.myPerformance.supervisor.position}`)
      } catch {
        doc.text(20, 35, `Jawatan: -`)
      }
      const tableDataSuggest = [[`${this.suggestions}`]]

      doc.autoTable({
        startY: 40,
        headStyles: { halign: 'center', fillColor: '#000000' },
        styles: {
          cellPadding: 5,
        },
        body: tableDataSuggest,
        theme: 'grid',
        margin: {
          left: 20,
          right: 20,
        },
      })

      doc.addImage(logo, 'png', 199, 281, 7.8125, 12.5, 'a', 'FAST')
      // Proposed Training End

      doc.save("Employee Performance.pdf")
      // const dataURL = doc.output("bloburl")
      // window.open(dataURL, '_blank')
    },
    getWeightage() {
      const params = new URLSearchParams()

      params.append(`subsidiary_id`, this.empData.subsidiary_id)

      params.append('role_id', this.empData.grade_set_id)

      const tm = this.empData.grade_set_id === 1 || this.empData.grade_set_id === 2

      // console.log('tm', tm)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        // console.log('res', response)
        const res = response.data.data

        this.pemberat = {
          KRA: this.empData.category === 'Non Executive' ? `${res.overall_assesment}%` : `${res.key_result_area}%`,
          CoreCompetencies: `${res.core_competency}%`,
          BehaviouralCompetencies: `${res.behavioural_competency}%`,
          TrainingAndDevelopment: tm ? `${res.training + res.self_learning}%` : `${res.training}%`,
          OtherContributions: `${res.internal_industry_involement}%`,
          SummaryScore: '100%',
        }
        // console.log('set weightage', this.empData.jd_kra_status)
        // console.log(this.pemberat)
      })
    },
    getScore(set, id, userId) {
      // console.log('set', set)
      this.$axios.get(`${this.$baseUrl}/myperformances/overall_performance?cycle_id=${id}&user_id=${userId}`).then(response => {
        // console.log('res supp', response)
        this.overall_total2 = response.data.data.overall_total
        this.overall_total_manager = response.data.data.overall_total_manager
        this.contributionPercent = response.data.data.hasContribution
        const status = response.data.data.internal_industry_status === 'enable'
        // console.log("contribution", this.contributionPercent)
        let finalKRA = ''
        let finalKRAManager = ''
        if (set === 0){
          finalKRA = response.data.data.p1_job_description
          finalKRAManager = response.data.data.p1_job_description_manager
        } else {
          finalKRA = response.data.data.p1_kra
          finalKRAManager = response.data.data.p1_kra_manager
        }
        const { p2_competency_core } = response.data.data
        const { p2_competency_behaviour } = response.data.data
        const { p3_training_development } = response.data.data
        const { p3_contribution } = response.data.data

        const { accumulative1 } = response.data.data
        const { accumulative2 } = response.data.data
        const { accumulative3 } = response.data.data
        const { accumulative4 } = response.data.data
        const { overall_total } = response.data.data

        const { p2_competency_core_manager } = response.data.data
        const { p2_competency_behaviour_manager } = response.data.data
        const { p3_training_development_manager } = response.data.data
        const { p3_contribution_manager } = response.data.data

        const { accumulative1_manager } = response.data.data
        const { accumulative2_manager } = response.data.data
        const { accumulative3_manager } = response.data.data
        const { accumulative4_manager } = response.data.data
        const { overall_total_manager } = response.data.data

        // const employeeRole = this.empData.role

        // let currentRole = ''

        // employeeRole.forEach(x => {
        //   if (x === 2){ // non-exec
        //     // console.log('Non Executive')
        //     if (set === 0) {
        //       currentRole = 'nonExecJD'
        //     } else {
        //       currentRole = 'nonExecKRA'
        //     }
        //   }
        //   if (x === 3){ // exec
        //     // console.log('Executive')
        //     if (this.empData.grade_set === 'Middle Management'){
        //       currentRole = 'topManagement'
        //     } else {
        //       currentRole = 'exec'
        //     }
        //   }
        //   if (x === 4){ // top management
        //     // console.log('Top Management')
        //     currentRole = 'topManagement'
        //   }
        // })

        // // this.triggerGenerateReport()
        // const pemberat = {
        //   nonExecKRA: {
        //     KRA: '60%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '15%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        //   nonExecJD: {
        //     KRA: '60%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '15%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        //   exec: {
        //     KRA: '60%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '15%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        //   topManagement: {
        //     KRA: '50%',
        //     CoreCompetencies: '20%',
        //     BehaviouralCompetencies: '25%',
        //     TrainingAndDevelopment: '5%',
        //     OtherContributions: '10%',
        //     SummaryScore: '100%',
        //   },
        // }

        const newScoreList = [
          {
            keputusan: this.kra === 0 ? `${this.$t('Overall Assessment')}` : `${this.$t('Pencapaian Bidang Tugas Utama')}`, pemberat: this.pemberat.KRA, markah: finalKRA.toString(), markah_terkumpul: accumulative1.toString(), markah_manager: finalKRAManager.toString(), markah_terkumpul_manager: accumulative1_manager.toString(),
          },
          {
            keputusan: `${this.$t('Kompetensi Teras')}`, pemberat: this.pemberat.CoreCompetencies, markah: p2_competency_core.toString(), markah_terkumpul: accumulative2.toString(), markah_manager: p2_competency_core_manager.toString(), markah_terkumpul_manager: accumulative2_manager.toString(),
          },
          {
            keputusan: `${this.$t('Kompetensi Tingkahlaku')}`, pemberat: this.pemberat.BehaviouralCompetencies, markah: p2_competency_behaviour.toString(), markah_terkumpul: accumulative3.toString(), markah_manager: p2_competency_behaviour_manager.toString(), markah_terkumpul_manager: accumulative3_manager.toString(),
          },
          {
            keputusan: `${this.$t('Latihan dan Pembangunan')}`, pemberat: this.pemberat.TrainingAndDevelopment, markah: p3_training_development.toString(), markah_terkumpul: accumulative4.toString(), markah_manager: p3_training_development_manager.toString(), markah_terkumpul_manager: accumulative4_manager.toString(),
          },
          // {
          //   keputusan: `${this.$t('Penglibatan Dalaman / Industri')}`, pemberat: pemberat[currentRole].OtherContributions, markah: p3_contribution.toString(), markah_terkumpul: overall_total.toString(), markah_manager: p3_contribution_manager.toString(), markah_terkumpul_manager: accumulative4_manager.toString(),
          // },
          {
            keputusan: `${this.$t('Skor Keseluruhan (%)')}`, pemberat: this.pemberat.SummaryScore, markah: overall_total.toString(), markah_terkumpul: '', markah_manager: overall_total_manager.toString(), markah_terkumpul_manager: '',
          },
        ]
        // this.table.scoreList = newScoreList

        const contr = {
          keputusan: 'Penglibatan Dalaman / Industri', pemberat: this.pemberat.OtherContributions, markah: p3_contribution, markah_terkumpul: overall_total, markah_manager: p3_contribution_manager, markah_terkumpul_manager: accumulative4_manager,
        }

        if (status){
          // this.table.scoreList = newScoreList.slice(3) + contr + newScoreList[4]
          this.table.scoreList = newScoreList.slice(0, 4).concat(contr).concat(newScoreList.slice(4))
        } else {
          this.table.scoreList = newScoreList
        }

        // eslint-disable-next-line camelcase
        this.contribution = p3_contribution
        this.show = false
        // this.autoTable()
        // return this.table.scoreList

        // trigger generate report
        // this.triggerGenerateReport()
        // console.log("checkscore", this.table.scoreList)
      })
        .catch(() => {
          this.show = false
        })
    },

  },
}
</script>
<style>
.styleFont {
    font-size: 2.5rem !important;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-size: 1.5rem;
    position: static;
}

</style>
