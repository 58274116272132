<template>
  <div class="">
    <p class="saj-header text-center">
      {{ $t('Score Logs') }}
    </p>
    <div
      v-if="scoreLog.length === 0"
      class="p-1 saj-text d-flex justify-content-center"
      style=" border-style: solid;
              border-radius: 6px;
              border-width: 1.5px;
              border-color: grey;
            "
    >
      {{ $t('No score log') }}
    </div>
    <div
      v-else
      class="p-1"
      style="height: 80vh; overflow-y: scroll"
    >
      <div
        v-for="(item, idx) in scoreLog"
        :key="idx"
        class="pb-2"
        style="border-left: solid;
    border-width: 2px;"
      >
        <div class="timeline-item">
          <div
            class="timeline-item-point"
            style="background-color: red"
          />
          <slot>
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6 class="saj-subtitle">
                {{ DateChanged(item.updated_at) }} |
                {{ TimeChanged(item.updated_at) }}
              </h6>
            <!-- <small
              class="text-nowrap text-muted"
              v-text="item.prevScore"
            /> -->
            </div>
            <div class="mb-0">
              <p>{{ $t('Current Score') }}: {{ item.newScore }}</p>
              <p>{{ $t('Previous Score') }}: {{ item.prevScore }}</p>
              <p class="m-0">
                {{ $t('Score given by') }}: {{ item.full_name }} {{ item.role !== null ? '(' + item.role.name + ')' : '' }}
              </p>
            </div>
          </slot>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import moment from "moment"

export default {
  components: {

  },
  props: {
    perfId: {
      type: Number,
      default: null,
    },

  },
  data(){
    return {
      scoreLog: [],

    }
  },
  mounted(){
    this.viewScore()
  },
  methods: {

    viewScore(){
      const params = new URLSearchParams()

      params.append('performance_id', this.perfId)

      this.$axios.get(`${this.$baseUrl}/performance/score_history`, { params }).then(response => {
        const info = response.data.data
        this.scoreLog = info
        // console.log('info', info)
        // this.$bvModal.show('view-score')
      })
    },
    DateChanged(date) {
      const newDay = moment(date).format("D-MMM-yyyy")
      return newDay
    },
    TimeChanged(date){
      const newTime = moment(date).format("hh:mm a")
      return newTime
    },

  },
}
</script>

<style lang="scss" scoped>
  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    //top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid red;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient(white, white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -7px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

}
</style>
