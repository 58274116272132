<template>
  <div>
    <div
      class="modalScroll d-flex flex-column align-items-center"
      style="width: auto; height: auto"
    >
      <div
        class="mb-1 mt-1 d-flex justify-content-center"
        style="font-size: 1.9rem; font-weight: 500;"
      >
        {{ $t("Download Employee Performance") }}

        <br>
      </div>
      <!-- {{ employeeData.grade_set }} -->
      <div
        class="p-1"
        style=""
      >
        <div v-if="employeeData.grade_set === 'Top Management' || employeeData.grade_set === 'Senior Management'">
          <topManagement
            :emp-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </div>
        <div v-if="employeeData.grade_set === 'Executive'">
          <executive
            :emp-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </div>
        <div v-if="employeeData.grade_set === 'Middle Management'">
          <middleManagement
            :emp-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </div>
        <div v-if="employeeData.grade_set === 'Non Executive (Support)'">
          <nonExecSupport
            :emp-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </div>
        <div v-if="employeeData.grade_set === 'Non Executive (Supervisory)'">
          <nonExecSupervisory
            :emp-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </div>
      </div>
    </div>
  </div></template>

<script>
import {

} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import topManagement from '@/views/pages/summary/summary-report-pdf-top-management.vue'
import executive from '@/views/pages/summary/summary-report-pdf-executive.vue'
import middleManagement from '@/views/pages/summary/summary-report-pdf-middle-management.vue'
import nonExecSupport from '@/views/pages/summary/summary-report-pdf-nonExec-support.vue'
import nonExecSupervisory from '@/views/pages/summary/summary-report-pdf-nonExec-supervisory.vue'

export default {
  components: {
    topManagement,
    executive,
    middleManagement,
    nonExecSupport,
    nonExecSupervisory,
  },
  props: {
    employeeData: {
      type: null,
      default: null,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style>
.styleFont {
    font-size: 2.5rem !important;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-size: 1.5rem;
    position: static;
}

</style>
